// Common modal styles
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

// Base modal styles
.modal {
  background: transparent;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  margin: auto;

  // Note modal specific styles
  &.note-modal {
    .modal-content {
      padding: 20px;
      background: #ffffff;
      border-radius: 8px;
    }

    h2 {
      margin: 0 0 20px 0;
      font-size: 1.2em;
    }

    form {
      width: 100%;
    }

    textarea {
      width: 100%;
      box-sizing: border-box;
      margin: 10px 0;
      padding: 12px;
      border: 1px solid #ccc;
      border-radius: 4px;
      resize: vertical;
      min-height: 100px;
    }

    .modal-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      margin-top: 20px;
    }

    button {
      padding: 8px 20px;
      border-radius: 4px;
      border: none;
      cursor: pointer;

      &:first-child {
        background: #fff;
        border: 1px solid #ccc;
      }

      &:last-child {
        background: #6750A4;
        color: white;
      }
    }
  }

  // User modal specific styles
  &.user-modal {
    .modal-content {
      background: white;
      border-radius: 8px;
      width: 100%;
      max-height: 90vh;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .modal-header {
      padding: 1rem;
      border-bottom: 1px solid #ddd;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: white;
      border-radius: 8px 8px 0 0;
    }

    .modal-body {
      padding: 1rem;
      overflow-y: auto;
      overflow-x: hidden;
      flex: 1;
      
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;

        &:hover {
          background: #555;
        }
      }
    }

    .modal-footer {
      padding: 1rem;
      border-top: 1px solid #ddd;
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      background: white;
      border-radius: 0 0 8px 8px;
    }
  }
}

// User modal additional styles
.settings-section {
  border-top: 1px solid #eee;
  margin-top: 1rem;
  padding-top: 1rem;

  h3 {
    margin-bottom: 1rem;
  }
}

.info-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  border-radius: 50%;
  background-color: #6750A4;
  color: white;
  font-size: 12px;
  font-weight: bold;
  cursor: help;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #7965AF;
  }
}

.form-group {
  margin-bottom: 1rem;
  position: relative;
  
  select {
    width: 100%;
    padding: 12px 16px;
    font-size: 1rem;
    border: 1px solid #79747E;
    border-radius: 4px;
    background-color: transparent;
    appearance: none;
    cursor: pointer;
    outline: none;
    transition: all 0.2s ease;
    color: #1C1B1F;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23666666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 16px;

    &:hover {
      border-color: #49454F;
      background-color: #F7F2FA;
    }

    &:focus {
      border-color: #6750A4;
      border-width: 2px;
      padding: 11px 15px;
    }

    &:disabled {
      background-color: #F7F2FA;
      border-color: #CAC4D0;
      color: #1C1B1F;
      opacity: 0.38;
      cursor: not-allowed;
    }

    option {
      padding: 12px;
      background-color: #FFFFFF;
      color: #1C1B1F;
      font-size: 1rem;

      &:checked {
        background-color: #E8DEF8;
      }

      &:hover {
        background-color: #F7F2FA;
      }
    }
  }

  label {
    display: block;
    margin-bottom: 8px;
    color: #49454F;
    font-size: 0.875rem;
    font-weight: 500;
  }
}

.checkbox-group {
  display: flex;
  align-items: center;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;

  &:hover {
    opacity: 0.7;
  }
} 